<template>
  <dv-full-screen-container class="bg">
    <!-- 顶部 -->
    <Header
      pageNum="1/7"
      :villageName="headerData.village_name"
      :slogan="headerData.slogan"
      :curCountDown="curCountDown"
      @onShowNext="onShowNext"
    />

    <!-- 底部 -->
    <div class="content">
      <div class="c-left">
        <div class="cl-top"></div>
        <div class="cl-bottom ps-r" ref="videoPlayer1">
          <video-player
            v-if="isShowVideo"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            @play="onPlayerPlay($event)"
          >
          </video-player>
        </div>
      </div>
      <div class="c-right">
        <div class="cr-top">
          <div class="crt-title f-ai-c pt-36 pl-10">
            <img class="mr-9" src="../assets/common/标题边框@2x.png" />{{
              pageData.video_name
            }}
          </div>
          <!-- <div class="crt-date ml-36">(2021年-2022年)备份</div> -->
          <div class="crt-view f-ai-c fz-18 mt-30 ml-21">
            <img class="mr-9" src="../assets/common/观看次数@2x.png" />观看人次:
            {{ pageData.view_count }}
          </div>
          <div class="crt-support f-ai-c fz-18 mt-12 ml-21">
            <img class="mr-9" src="../assets/common/点赞数@2x.png" />点赞数:
            {{ pageData.support_count }}
          </div>
          <vue-seamless-scroll
            :data="listData"
            :class-option="optionHover"
            class="seamless-warp"
            v-if="video_introduction"
          >
            <!-- <ul >
          <li v-for="(item,index) in listData" :key="index" > {{video_introduction}}</li> -->
            <!-- <li v-for="(item,index) in listData" :key="index">
                <span class="title" v-text="item.title"></span><span class="date" v-text="item.date"></span>
            </li> -->
            <!-- </ul> -->
            <div class="crt-dec fz-20" v-html="video_introduction"></div>
          </vue-seamless-scroll>
          <!-- <vueSeamlessScroll
            class="seamless-warp"
            :data="[{}]"
            :class-option="defaultOption"
          >
          <div class="crt-dec fz-20" v-html="video_introduction">
          </div>
          </vueSeamlessScroll> -->
        </div>
        <div class="cr-bottom">
          <div class="crb-follow f-ai-c fz-14">
            <img class="mr-9" src="../assets/common/关注@2x.png" />村民关注：{{
              config.follow_count
            }}人
          </div>
          <div class="crb-visit f-ai-c fz-14">
            <img
              class="mr-9"
              src="../assets/common/访问@2x.png"
            />日访问数量：{{ config.visit_count || 0 }}次
          </div>
          <div class="crb-qr f-c mt-13">
            <div class="qr-box">
              <img :src="config.qrcode_img" />
            </div>
          </div>
          <div class="crb-tips fz-14 f-c">扫码关注</div>
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>
<script>
import { getVideo, getVideos, getConfig, getHome } from "@/api/village";
import vueSeamlessScroll from "vue-seamless-scroll";
import Header from "./components/Header";

export default {
  data() {
    return {
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        isShowVideo: false,
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      pageData: {},
      headerData: {},
      isShowVideo: false,
      config: {
        visit_count: 6,
        qrcode_img:
          "https://r.517lc.cn/Upload/WxQrCode/202011200030452461/73b9710771eb45a9886f7da242b8117d.png",
        countdown: 60,
      },
      curCountDown: 60,
      interval: null,
      id: "",
      video_introduction: "",
      listData: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    };
  },
  async mounted() {
    const id = this.$route.query.village_id || "6";
    this.id = id;
    this.getVideos(id);
    this.getConfig(id);
    this.getHome(id);
  },
  components: {
    vueSeamlessScroll,
    Header,
  },
  computed: {
    optionHover() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    onShowNext() {
      clearInterval(this.interval);
      this.$router.push({
        path: "survey",
        query: { village_id: this.id },
      });
    },
    onPlayerPlay($event) {
      console.log("player play!", $event);
    },
    async getVideos(id) {
      const { data } = await getVideos({
        village_id: id,
        page: 1,
        rows: 9999,
      });
      if (data.code === "200") {
        this.pageData = data.data.pagedata[0];
        this.playerOptions.sources[0].src = data.data.pagedata[0].video_url;
        this.playerOptions = JSON.parse(JSON.stringify(this.playerOptions));
        this.isShowVideo = true;
        getVideo({ video_id: data.data.pagedata[0].video_id }).then((data) => {
          this.video_introduction = data.data.data.video_contents;
        });
      }
    },
    async getConfig(id) {
      const { data } = await getConfig({
        village_id: id,
      });
      if (data.code === "200") {
        this.config = data.data;
        this.setTimer(data.data.countdown);
      }

      console.log("data", data);
    },
    async getHome(id) {
      const { data } = await getHome({
        village_id: id,
      });
      if (data.code === "200") {
        this.headerData = data.data;
      }
    },
    setTimer(time) {
      let that = this;
      that.curCountDown = time || 60;
      that.interval = setInterval(function () {
        that.curCountDown = that.curCountDown - 1;
        if (that.curCountDown <= 0) {
          clearInterval(that.interval);
          that.$router.push({
            path: "survey",
            query: { village_id: that.id },
          });
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
// @function h($num) {
//   @return ($num/1080 * 100) + vh;
// }
.seamless-warp {
  height: 229px;
  overflow: hidden;
}
.bg {
  background: #040b13;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: h(100);
  width: 100%;
  background: url("../assets/common/大标题_底板@2x.png") no-repeat;
  background-size: 100% 100%;
  color: #56fefe;
  font-size: 24px;
  .textLeft,
  .textRight {
    margin-bottom: h(27);
  }
  .textLeft {
    margin-left: 100px;
  }
  .textRight {
    display: flex;
    margin-right: 20px;
    .pageNum {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      width: 96px;
      height: h(40);
      line-height: h(40);
      background: #0c2b46;
      border-radius: 20px;
      border: 1px solid #01f9ed;
    }
  }
  .textCenter {
    margin-bottom: h(10);
    display: flex;
    font-size: 34px;
    .name {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      line-height: h(53);
      font-size: 32px;
      font-weight: 700;
      width: 126px;
      height: h(53);
      background: url("../assets/common/选中@2x.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
.h-page {
  height: h(40);
  width: 96px;
  border: 1px solid #01f9ed;
  border-radius: 20px;
  margin: h(13) 0 0 31px;
  line-height: h(40);
  text-align: center;
  font-size: 20px;
  background: #0c2b46;
}
.content {
  padding: h(41) 0 0 26px;
  display: flex;
}

.cl-top {
  height: h(42);
  width: 1472px;
  background: url("../assets/common/视频头部边框@2x.png") no-repeat;
  background-size: 100% 100%;
}
.cl-bottom {
  margin-top: 21px;
  width: 1472px;
  height: h(836);
  border: 11px solid #56fefe;
  box-sizing: border-box;
  border-radius: 10px;
  img {
    width: 100%;
    height: 100%;
  }
  .count-down {
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cd-top {
    font-size: 48px;
  }
  .cd-bottom {
    font-size: 140px;
  }
}
.c-right {
  margin-left: 20px;
}
.cr-top {
  width: 380px;
  height: h(604);
  background: url("../assets/common/宣传片外框@2x.png") no-repeat;
  background-size: 100% 100%;
  color: white;
}
.crt-title {
  font-size: 22px;
  color: #44ffff;
  img {
    width: 16px;
    height: h(40);
  }
}
.crt-date {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
.crt-view {
  img {
    width: 24px;
    height: h(24);
  }
}
.crt-support {
  img {
    width: 24px;
    height: h(24);
  }
}
.crt-dec {
  margin: h(24) 21px;
}
.cr-bottom {
  width: 380px;
  height: h(275.5);
  margin-top: h(18);
  background: url("../assets/common/背景_首页_隐患情况总数@2x.png") no-repeat;
  background-size: 100% 100%;
  color: white;
}
.crb-follow {
  padding: h(44) 0 0 79px;
  img {
    width: 25px;
    height: h(17);
  }
}
.crb-visit {
  padding: h(12) 0 0 79px;
  img {
    width: 25px;
    height: h(17);
  }
}
.crb-qr {
  img {
    width: h(106);
    height: h(106);
  }
}
.qr-box {
  border-radius: 50%;
  background: white;
  padding: 3px;
  overflow: hidden;
}
.crb-follow {
}
.seamless-warp {
  width: 100%;
  height: h(390);
  margin-top: h(10);
  overflow: hidden;
}
</style>
